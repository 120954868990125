<template>
    <div>
      <p class="login-box-msg">Recover your password</p>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider class="form-group" name="username" rules="required" tag="div" v-slot="{ errors }">
            <input aria-label="Username" class="form-control"
                   placeholder="Username" type="text" v-model="form.User">
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider class="form-group" rules="reCaptcha" tag="div" v-slot="{ errors }">
            <vue-recaptcha :sitekey="siteKey" @reset="reset" @verify="verify"
                           ref="reCaptcha" v-model="form.reCaptcha"></vue-recaptcha>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <button :disabled="isLoading" class="btn btn-success btn-block btn-flat" type="submit">
            <span v-if="!isLoading">Recover</span>
            <span v-if="isLoading">
                <i class="fa fa-circle-o-notch fa-spin"></i>
              </span>
          </button>
          <div style="margin-top: 8px">
            <router-link :to="{ name: 'login' }" tag="a">Back to Login</router-link>
          </div>
        </form>
      </ValidationObserver>
    </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { saveNotification } from "../lib/utils";

export default {
  name: "ForgotPassword",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_reCAPTCHA_SITE_KEY,
      form: {
        User: "",
        reCaptcha: "",
        output: "json",
      },
      isLoading: false,
    };
  },
  methods: {
    verify(response) {
      this.form.reCaptcha = response;
    },
    reset() {
      this.form.reCaptcha = "";
    },
    onSubmit() {
      this.isLoading = true;
      let payload = this.form;
      payload = Object.assign(payload, { "g-recaptcha-response": payload.reCaptcha });
      delete payload.reCaptcha;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/RecoverPassword`, {
        params: payload,
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          if (response.data.status_code === 400) {
            this.$refs.reCaptcha.reset();
            this.$toast.fire("", response.data.reason, "error");
          } else {
            // Reset google reCaptcha
            this.$refs.reCaptcha.reset();
            this.$toast.fire("", response.data.data[0], "success");
            // Redirect
            this.$router.push({ name: "login" });
          }
        }
      }).catch(error => {
        this.$toast.fire("", error.response.data, "error");
        saveNotification(error.response.data);
        this.isLoading = false;
        this.$refs.reCaptcha.reset();
      });
    },
  },
};
</script>

<style scoped></style>
